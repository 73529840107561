import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ImageDownload from "../../../components/ImageDownload"
import ImageDownloadSocial from "../../../components/ImageDownloadSocial"



const IndexPage = () => {
    const root = "/img/download/tribe-night"

    return (
        <Layout pageTitle="Tribe Night - Standard Graphics">
            <Row>
                <Col>
                    <Link to="/events/tribe-night">Back</Link>
                </Col>
            </Row>

           <Row className="mt-5">
                <Col>
            <h1>General Promo</h1>
            </Col>
    </Row>
                
            <ImageDownload
            root ="/img/download/tribe-night"
            filename="tribe-night-general"
            name="Tribe Night General Promo"
            text="Use this to promote Tribe Night events on your social media"
            text2=""
            squareHref="https://www.canva.com/design/DAFqeKFrH6I/rGJq0cQGs_0dESzvixb4lA/view?utm_content=DAFqeKFrH6I&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            horizontalHref="https://www.canva.com/design/DAFi_PfT9Qg/iYC_y93henWf5m3m4RDGNw/view?utm_content=DAFi_PfT9Qg&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            copyHref="https://docs.google.com/document/d/1PFbVwKXoewNFnZqdlyfPjCvdQYDS6lXCQFarGYwSM-0/edit?usp=sharing"
            />  
        

            
        </Layout>

        
    )
}

export default IndexPage
